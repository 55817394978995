import api from '@/services/api'

export default {
  getCoupons(page) {
    return api.get('/coupons?page=' + page)
  },
  createCoupon(data) {
    return api.post('/coupons', data)
  },
  updateCoupon(data) {
    return api.put(`/coupons/${data._id}`, data)
  },
  removeCoupon(data) {
    return api.delete(`/coupons/${data._id}`)
  },
  activateCoupon(id) {
    return api.put(`/coupons/${id}`, { active: true })
  },
  deactivateCoupon(id) {
    return api.put(`/coupons/${id}`, { active: false })
  },
}