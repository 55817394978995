<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import CouponService from '@/services/coupon'
import { VMoney } from 'v-money'

export default {
  page: {
    title: "Cupons",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  directives: { money: VMoney },
  data() {
    return {
      title: "Cupons",
      coupons: [],
      fields: [
        { key: 'code', sortable: true, label: 'Código' },
        { key: 'type', sortable: true, label: 'Tipo de Desconto', formatter: (value) => {
          if (value === 'free_shipping') {
            return 'Frete Grátis';
          }

          return value === 'value' ? 'por Valor' : 'por Percentual';
        } },
        { key: 'value', sortable: true, label: 'Valor', formatter: (value, index, item) => {
          if (item.type === 'free_shipping') {
            return '-';
          }

          if (item.type === 'value') {
            return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
          }

         return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}%`
        } },
        { key: 'status', sortable: true, label: 'Status' },
        { key: 'usage', sortable: true, label: 'Utilização', formatter: (value, index, item) => {
          return `${item.usageCount}/${item.usageLimit}`;
        } },
        { key: 'minimumSaleValue', sortable: true, label: 'Pedido Mínimo', formatter: (value) => {
          return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        } },
        { key: 'actions', label: 'Ações' },
      ],
      modalError: '',
      coupon: {
        code: '',
        type: 'value',
      },
      searchValue: '',
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 20,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
    };
  },
  computed: {
    rows() {
      return this.coupons.length;
    },
    startIndex() {
      return (this.currentPage * this.perPage) - this.perPage + 1;
    },
    endIndex() {
      const endIndex = (this.currentPage * this.perPage);
      if (this.coupons.length < endIndex) {
        return this.coupons.length;
      }

      return endIndex;
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load(loader = null) {
      if (!loader) {
        loader = this.$loading.show();
      }

      CouponService.getCoupons().then(res => {
        this.coupons = res.data;

        loader.hide();
      }, err => {
        // eslint-disable-next-line no-console
        console.log(err);
      })
    },
    newCoupon() {
      this.coupon = {
        description: '',
      }
      this.modalError = '';

      this.$bvModal.show('modal-edit');
    },
    editCoupon(item) {
      this.coupon = {
        _id: item._id,
        code: item.code,
        type: item.type,
        value: item.value.toFixed(2).replace('.', ','),
        minimumSaleValue: item.minimumSaleValue.toFixed(2).replace('.', ','),
        usageLimit: item.usageLimit,
        oneUsePerUser: item.oneUsePerUser,
      }
      this.modalError = '';

      this.$bvModal.show('modal-edit');
    },
    async saveCoupon() {
      this.modalError = '';
      // if (!this.coupon.description) {
      //   return this.modalError = 'Informe a descrição';
      // }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.coupon._id) {
          await CouponService.updateCoupon(this.coupon);
        } else {
          await CouponService.createCoupon(this.coupon);
        }

        this.$toast.open('Dados atualizados com sucesso');

        this.load(loader);
      } catch (err) {
        loader.hide();
      }

    },
    async remove() {
      this.$bvModal.hide('modal-remove');

      let loader = this.$loading.show();
      await CouponService.removeCoupon(this.coupon);

      this.$toast.open('Registro removido com sucesso');

      this.load(loader);
    },
    removeCoupon(item) {
      this.coupon = {
        _id: item._id,
        code: item.code,
      }

      this.$bvModal.show('modal-remove');
    },
    activateCoupon(id) {
      let loader = this.$loading.show();

      CouponService.activateCoupon(id).then(() => {
        this.load(loader);
        this.$toast.open('Cupom ativado com sucesso');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao ativar o cupom. Tente novamente.');
      })
    },
    deactivateCoupon(id) {
      let loader = this.$loading.show();

      CouponService.deactivateCoupon(id).then(() => {
        this.load(loader);
        this.$toast.open('Cupom desativado com sucesso');
      }).catch(() => {
        loader.hide();
        this.$toast.error('Ocorreu um erro ao desativar o cupom. Tente novamente.');
      })
    }
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body"> 
            <div class="row mb-2">
              <div class="col-sm-12">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newCoupon">
                    <i class="mdi mdi-plus mr-1"></i> Novo Cupom
                  </button>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="coupons"
                :fields="fields"
                responsive="sm"
                head-variant="light"
                :per-page="perPage"
                :current-page="currentPage"
              >
                <template v-slot:cell(status)="row">
                  <span
                    class="badge badge-pill badge-soft-success font-size-12"
                    :class=" {
                      'badge-soft-danger': !row.item.active,
                      'badge-soft-warning': row.item.status === 'awaitingPayment',
                    }"
                  >{{row.item.active ? 'Ativo' : 'Inativo'}}</span>
                </template>   

                <template v-slot:cell(actions)="row">
                  <b-dropdown class="card-drop" variant="white" dropleft toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18"></i>
                    </template>

                    <b-dropdown-item href="javascript: void(0);" v-if="row.item.active" @click="deactivateCoupon(row.item._id)">
                      <i class="fas fa-minus-circle text-danger mr-2 mt-2"></i>
                      Inativar
                    </b-dropdown-item>
                    <b-dropdown-item v-if="!row.item.active" @click="activateCoupon(row.item._id)">
                      <i class="fas fa-play-circle text-success mr-2 mt-2"></i>
                      Ativar
                    </b-dropdown-item>
                    <b-dropdown-item href="javascript: void(0);" @click="editCoupon(row.item)">
                      <i class="fas fa-edit text-warning mr-2 mt-2"></i>
                      Editar
                    </b-dropdown-item>
                    <b-dropdown-item v-b-modal.modal-remove @click="removeCoupon(row.item)">
                      <i class="fas fa-trash-alt text-danger mr-2 mt-2"></i>
                      Excluir
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </div>
          </div>
        </div>

        <div class="row justify-content-md-between align-items-md-center mt-4">
          <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{rows}}</div>

          <div class="col-xl-5">
            <div class="text-md-right float-xl-right mt-2 pagination-rounded">
              <b-pagination
                v-model="currentPage" 
                :total-rows="rows"
                :per-page="perPage"
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-edit" :title="coupon._id ? 'Alterando Coupon' : 'Novo Cupom'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" class="mt-3" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="code">Código do Cupom</label>
            <input id="code" name="code" type="text" class="form-control" v-model="coupon.code" />
          </div>
        </div>

        <div class="col-6">
           <div class="form-group">
            <label for="code">Tipo de Desconto</label>
            <select class="form-control" v-model="coupon.type">
              <option value="value">por Valor</option>
              <option value="percentage">por Percentual</option>
              <option value="free_shipping">Frete Grátis</option>
            </select>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-4">
          <div class="form-group">
            <label for="price">Desconto</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <span v-if="coupon.type === 'value'" id="validationTooltipUsernamePrepend" class="input-group-text">R$</span>
                <span v-if="coupon.type === 'percentage'" id="validationTooltipUsernamePrepend" class="input-group-text">%</span>
              </div>
              
              <input
                id="value"
                name="value"
                type="tel"
                class="form-control"
                v-model="coupon.value"
                v-money="money"
                :disabled="coupon.type === 'free_shipping'"
              />
            </div>
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label for="price">Pedido Mínimo</label>
            <input
              id="minimumSaleValue"
              name="minimumSaleValue"
              type="tel"
              class="form-control"
              v-model="coupon.minimumSaleValue"
              v-money="money"
            />
          </div>
        </div>

        <div class="col-4">
          <div class="form-group">
            <label for="price">Limite de Uso</label>
            <input id="usageLimit" name="usageLimit" type="tel" class="form-control" v-model="coupon.usageLimit" />
          </div>
        </div>

        <div class="col-12 mt-1">
          <div class="form-group">
            <div class="custom-control custom-checkbox mb-3">
              <input type="checkbox" class="custom-control-input" id="one_use_per_user" v-model="coupon.oneUsePerUser" />
              <label class="custom-control-label" for="one_use_per_user">Permitir apenas uma utilização por usuário</label>
            </div>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveCoupon()">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-remove"
      title="Excluir Registro?"
      title-class="font-18"
      centered
    >
      <p>Confirma a exclusão do cupom <b>{{coupon.code}}</b>?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="remove">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .form-control:disabled {
    background-color: #eee !important;
  }
</style>